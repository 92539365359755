import React from 'react';
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { lan } from "../lan/index.js";
import MetaTags from '../component/MetaTags.jsx';


const TermsConditions = () => {
    let date = new Date()
    const data = lan.en;
  return (
    <>
    <Navbar />
    
    <div className="container">
    <div className="centered-content">
      <h1>Terms & Conditions</h1>
      <p><em>Last updated on [28-10-2023]</em></p>

      <p>Welcome to the Koderbox website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Koderbox's relationship with you in relation to this website.</p>

      <p>The term 'Koderbox,' 'us,' or 'we' refers to the owner of the website, whose registered/operational office is [Rz-46 Vinoba Enclave Jharoda Kalan CRPF Camp Najafgarh New Delhi 110072]. The term 'you' refers to the user or viewer of our website.</p>

      <p><strong>1. General Information</strong></p>
      <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>

      <p><strong>2. Accuracy and Performance</strong></p>
      <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>

      {/* Continue with other sections here... */}

      <p><strong>10. Limitation of Liability</strong></p>
      <p>We, as a merchant, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction on account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</p>
    </div>
    </div>
    <Footer value={data} />
    <MetaTags title={"Terms & Conditions | Koderbox"} />
    </>
  );
};

export default TermsConditions;
