import * as React from "react";
import "../../gatsby-browser.js";
import Navbar from "../component/Navbar/Navbar.js";
import Footer from "../component/Footer/Footer.js";
import { motion } from "framer-motion";
import Services from "../static/img/img/work.png";
// import { lan_data } from "../component/Local/local";
import { lan } from "../lan/service.js";
import poster from "../static/img/german/german-poster.png";
import german1 from "../static/img/german/german-1.png";
import german2 from "../static/img/german/german-2.png";
import german3 from "../static/img/german/german-3.png";
import german4 from "../static/img/german/german-1.webp";
import german5 from "../static/img/japanese-5.png";
import { Link } from "gatsby";
import ContactForm from "../component/Courses/ContactForm.js";
import MetaTags from "../component/MetaTags.jsx";
import Price from "../component/Price.jsx";
import JapaneseCourseCard from "../component/JapaneseCourseCard/JapaneseCourseCard.jsx";
import GermanCourseCard from "../component/GermanCourseCard/GermanCourseCard.jsx";



class GermanClasses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      name: [],
      email: [],
      phone: [],
      message: []
    };
  }

  getName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  getEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  getPhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  getMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  render() {
   
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const { data, loading } = this.state;

    return (
      <>
        <Navbar />
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <section id="german-classes" className="course-banner">
            {/* <div className={"page_header"}>
              <div className={"row language-banner"}> */}
                <div className={" language-content "}>
                  <h1 align="center">
                  MASTER GERMAN COMMUNICATION AT KODERBOX
                  </h1>
         
                    <p align="center">
                      {lan.en?.["german-class"]?.["head"]?.map((item) => (
                       
                        <>
                          {item} <br />
                        </>
                      ))}
                    </p>
                
                </div>
               <div className="japanese-form">

                  <ContactForm />
               </div>
                

              {/* </div>
            </div> */}
          </section>

         <div className="container">
            <div className="row about-extra">
              <div align={"center"}>
                <h2 className={"about_head"}>
                  ABOUT <span className="highlighted-text">KODERBOX GERMAN CLASS</span> {" "}
                </h2>
              </div>
              <Price />
              <div className="about__box">
                <div className="about__img--box wow fadeInUp">
                  <img
                    src={german2}
                    className="img-fluid"
                    alt=""
                    width="400"
                  />
                </div>
                <div className="about__content wow fadeInUp pt-5 pt-lg-0">
                  <p className={"service_about"}>
                    {lan.en?.["german-class"]?.["about"][0]}
                  </p>
                </div>
              </div>
              <div className="about__box">
                <div className="about__content wow fadeInUp pt-5 pt-lg-0">
                  <p className={"service_about"}>
                    {lan.en?.["german-class"]?.["about"][1]}
                  </p>
                </div>
                <div className="about__img--box wow fadeInUp">
                  <img
                    src={german1}
                    className="img-fluid"
                    alt=""
                    width="400"
                  />
                </div>
              </div>
              <div className="about__box">
                <div className="about__img--box wow fadeInUp">
                  <img
                    src={german2}
                    className="img-fluid"
                    alt=""
                    width="400"
                  />
                </div>
                <div className="about__content wow fadeInUp pt-5 pt-lg-0">
                  <p className={"service_about"}>
                    {lan.en?.["german-class"]?.["about"][2]}
                  </p>
                </div>
              </div>
              <div className="about__box">
                <div className="about__content wow fadeInUp pt-5 pt-lg-0">
                  <p className={"service_about"}>
                    {lan.en?.["german-class"]?.["about"][3]}
                  </p>
                </div>
                <div className="about__img--box wow fadeInUp">
                  <img
                    src={german3}
                    className="img-fluid"
                    alt=""
                    width="400"
                  />
                </div>
              </div>
              <div className="about__box">
                <div className="about__img--box wow fadeInUp">
                  <img
                    src={german2}
                    className="img-fluid"
                    alt=""
                    width="400"
                  />
                </div>
                <div className="about__content wow fadeInUp pt-5 pt-lg-0">
                  <p className={"service_about"}>
                  {lan.en?.["german-class"]?.["about"][4]}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* ========================================  type =========================================== */}

          {/* <section id="services" className="section-bg japanese-processing">
            <div className={"container"}>
              <div className={"row"}>
                <div align={"center"}>
                  <h2 className={"process_head"}> Courses by Koderbox</h2>
                </div>
                <br />
                <br />
                <br />
                <div className={"col-sm-12"}>
                  <div className={"table-responsive"}>
                    <table className="table ">
                      <thead></thead>
                      <tbody>
                        <tr align={"center"}>
                          <th scope="row">JLPT N5 Course </th>
                          <td>Basic Japanese</td>
                          <td>Online</td>
                          <td>3-4 Months</td>
                          <td>Will Provide Certificate</td>
                          <td>Class strength: 5-8</td>
                          <td>₹6500</td>
                        </tr>
                      </tbody>

                      <tbody>
                        <tr align={"center"}>
                          <th scope="row">JLPT N4 Course </th>
                          <td>Fluent Japanese</td>
                          <td>Online</td>
                          <td>4-5 months</td>
                          <td>Will Provide Certificate</td>
                          <td>Class strength: 5-8</td>
                          <td>₹7500</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
           <GermanCourseCard viewCourse={true} />
           <JapaneseCourseCard buyNow={true} />

          <section id="services" className="">
           
              <header className="section-header">
                <h3 className="light-text">Why <span className="highlighted-text"> Koderbox German Class?</span>
                </h3>
              </header>
              <br />
              <div className="container">
              
                  
                    <p>{lan.en?.["german-class"]?.["service"][0]}</p>

                    <div className="row">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box mt-4">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                <span id={"one_point"} className="text-center mx-auto">
                                  {" "}
                                  {lan.en?.["german-class"]?.["service"][1][0]}
                                  <br />
                                </span>{" "}
                                {lan.en?.["german-class"]?.["service"][1][1]}
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6   wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box mt-4">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                <span id={"two_point"}>
                                  {lan.en?.["german-class"]?.["service"][2][0]}{" "}
                                  <br />
                                </span>{" "}
                                {lan.en?.["german-class"]?.["service"][2][1]}
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box mt-4">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                <span id={"one_point"}>
                                  {" "}
                                  {lan.en?.["german-class"]?.["service"][3][0]}
                                  <br />
                                </span>{" "}
                                {lan.en?.["german-class"]?.["service"][3][1]}
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box mt-4">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                <span id={"two_point"}>
                                  {lan.en?.["german-class"]?.["service"][4][0]}{" "}
                                  <br />
                                </span>{" "}
                                {lan.en?.["german-class"]?.["service"][4][1]}
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box mt-4">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                <span id={"one_point"}>
                                  {" "}
                                  {lan.en?.["german-class"]?.["service"][5][0]}
                                  <br />
                                </span>{" "}
                                {lan.en?.["german-class"]?.["service"][5][1]}
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box mt-4">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                <span id={"two_point"}>
                                  {lan.en?.["german-class"]?.["service"][6][0]}{" "}
                                  <br />
                                </span>{" "}
                                {lan.en?.["german-class"]?.["service"][6][1]}
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                   
                  </div>
                
              </div>
              <br />
          </section>

         

          <br />
          <br />

          <Footer />
        </motion.div>
        <MetaTags title={"German Course | Koderbox"} />
      </>
    );
  }
}

export default GermanClasses;
