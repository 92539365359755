export const lan = {
  en: {
    web: {
      head: [
        "We develop static, dynamic and e-Commerce portals for our Clients. " +
          "The solutions we design and develop also enable integration with existing " +
          "applications as well as third-party tools like social media apps and multiple" +
          "payment gateways",
      ],
      about: [
        "We develop static, dynamic and e-Commerce portals for our Clients. The solutions we design and develop " +
          "also enable integration with existing applications as well as third-party tools like social media apps and" +
          " multiple payment gateways. The Websites we develop are responsive with user-friendly and amazing designs.\n" +
          "From simple presentation websites to complex web applications, our team of full-stack web developers is ready " +
          "to craft effective web experiences that delight visitors and meet business requirements.\n" +
          "We have an expert team always keeping up with the latest Front-end and Back-end web development trends to " +
          "create high-quality web applications with clean code, innovative functionality, and logical layouts.",
      ],
      service: [
        "We have an expert team always keeping up with the latest Front-end and Back-end web development trends to create high-quality web applications with clean code, innovative functionality, and logical layouts",
        "Build customized web apps or progressive web apps that intuitively solve business problems. Create browser-agnostic apps that are easy to maintain due to a common code base across platforms.",
        "Build cloud-native web apps that can efficiently adapt to surges in demand, thus avoiding high latency and long loading times.",
      ],
    },

    mobile: {
      head: [
        "We develop static, dynamic and e-Commerce portals for our Clients. " +
          "The solutions we design and develop also enable integration with existing " +
          "applications as well as third-party tools like social media apps and multiple" +
          "payment gateways",
      ],
      about: [
        "Our Mobile App solutions combine strong mobile architecture built with the latest " +
          "technologies with good security and user experience design practices. From suggesting " +
          "a workable solution to implementing it; from subsequently deploying your applications to " +
          "the marketplace to maintaining and upgrading the applications, we provide full stack services." +
          "We at Koderbox Technologies assist you with mobile app development solutions for Android, " +
          "iOS and the Web platforms. We are featured as a one of the best mobile app developers in " +
          "Gurugram. We are helping businesses by providing advanced mobile application services.",
      ],
      service: [
        "Develop smart mobile apps that analyse user data to incorporate the power of AI-ML for giving users personalized experiences." +
          "Strategic knowledge of the different platforms and frameworks available in today’s rapidly evolving mobile world is a must for" +
          " successful Mobile App Development. The team at work in this skill-based area comprises professionals and developers with knowledge, " +
          "as well as a keen sense of design.",

        "We at Koderbox technologies believe in building the best mobile apps which offer a superlative interface along " +
          "with a great user experience. Our team of highly qualified mobile app developers is trained to use the best mobile " +
          "application development tools and technologies and develop some of the best mobile apps available in the market.\n" +
          "A high-quality mobile app is essential for all businesses on the digital platform. However, it’s not easy to create " +
          "a result-oriented mobile platform. Embrace the world of mobile by hiring our developers at Koderbox technologies in " +
          "Delhi. Our skilful and creative mobile app developers give you the right solution with our superior app.",
      ],
    },

    smo: {
      head: [
        "We develop static, dynamic and e-Commerce portals for our Clients. " +
          "The solutions we design and develop also enable integration with existing " +
          "applications as well as third-party tools like social media apps and multiple" +
          "payment gateways",
      ],
      about: [
        "Our experienced team of Search Engine Marketing strategists work tirelessly to " +
          "promote your website and to ensure that your brand achieves higher visibility " +
          "essentially through paid advertising. Search Engine Marketing (SEM) usually incorporates" +
          "Search Engine Optimization (SEO) by either adjusting or rewriting your website’s content" +
          "and changing the site architecture to enhance Pay Per Click (PPC) listings. We run paid ad " +
          "campaigns on platforms to make your website more attractive to your audience and also more " +
          "accessible for search engines to read.\n" +
          "Our social media marketing process revolves around a clear understanding of the objectives " +
          "that helps create a plan that leads to higher engagement and conversion rate on the web. We " +
          "leverage our expertise in Social Media Marketing to engage with our audience and convert them" +
          "into valuable customers.",
      ],
      service: [
        "Develop smart mobile apps that analyse user data to incorporate the power of AI-ML for giving users personalized experiences." +
          "Strategic knowledge of the different platforms and frameworks available in today’s rapidly evolving mobile world is a must for" +
          " successful Mobile App Development. The team at work in this skill-based area comprises professionals and developers with knowledge, " +
          "as well as a keen sense of design." +
          "We at Koderbox technologies believe in building the best mobile apps which offer a superlative interface along " +
          "with a great user experience. Our team of highly qualified mobile app developers is trained to use the best mobile " +
          "application development tools and technologies and develop some of the best mobile apps available in the market.\n" +
          "A high-quality mobile app is essential for all businesses on the digital platform. However, it’s not easy to create " +
          "a result-oriented mobile platform. Embrace the world of mobile by hiring our developers at Koderbox technologies in " +
          "Delhi. Our skilful and creative mobile app developers give you the right solution with our superior app.",
      ],
    },

    seo: {
      head: [
        "We develop static, dynamic and e-Commerce portals for our Clients. " +
          "The solutions we design and develop also enable integration with existing " +
          "applications as well as third-party tools like social media apps and multiple" +
          "payment gateways",
      ],
      about: [
        "SEO or Search Engine Optimization helps in generating organic traffic through search engines to optimize a website. Koderbox provide customized and innovative SEO solutions in Delhi to help its clients in their business growth. After all more traffic leads to more conversion.\n" +
          "Our Search Engine Optimization (SEO) strategies and tactics can increase organic search visibility on Google and assist in leads for your company.\n" +
          "At Koderbox, we assure you the most effective SEO services in Delhi that will give you long-lasting results. What makes us different from our competitors is that we pay close attention to the requirements of the clients instead of taking the one-size-fits-all approach. We offer customized services to fit the needs and budget of your business.",
      ],
      service: [
        "Our experts will get in touch with you and understand your business goals and " +
          "accordingly search for the keywords that should be targeted. The SEO process at " +
          "Koderbox involves HTML Meta tags, fine-tuning of content and navigation structure " +
          "to make it search engine friendly. These techniques will surely help you improve the" +
          " website's ranking. The website optimization is done keeping the target keywords and key phrases in mind.",
      ],
    },

    "japanese-class": {
      head: [
        "Are you eager to explore the richness of the Japanese language and culture?",
        "Look no further! ",
        "Koderbox Japanese School  is here to provide you with an exceptional learning experience that combines convenience, affordability, and quality education. ",
      ],
      about: [
        "At Koderbox Japanese School, We believe that learning a new language should be accessible to everyone, which is why we have designed affordable courses.  We offer competitive pricing options that fit within your budget, ensuring that language learning is within reach for all. ",
        "We are proud to be the only language institute in Delhi that offers Japanese language classes at such affordable prices without compromising on the quality of education.  We believe that language learning should be engaging and interactive. Our courses incorporate various multimedia resources, interactive exercises, and real-life scenarios to enhance your language skills. ",
        "Through our interactive approach, you'll get enough opportunities to practice your speaking, listening, reading, and writing skills. You'll also receive valuable feedback from our instructors to help you improve and refine your language abilities. ",
        "At Koderbox Japanese School, you'll become part of a supportive community of language learners. Our instructors are dedicated to your success and are always available to answer your questions and provide guidance. Additionally, you'll have the opportunity to connect with fellow students, share experiences, and practice your language skills together. ",
        "We believe that a supportive community enhances the language learning journey and creates a motivating environment for growth.  Start your journey today and discover the beauty of the Japanese language and culture from the comfort of your own home.",
      ],
      service: [
        "At Koderbox School, we take pride in our team of experienced and highly qualified Japanese language instructors. We provide you with the most bright and talented teachers who are passionate about teaching and are dedicated to your success ",
        ["Expert Instructors" , "Our instructors are native speakers with a deep understanding of both the language and culture. They possess the necessary expertise to guide students effectively through the language-learning process, ensuring that you receive top-notch instruction tailored to your specific needs and learning style."],
        ["Affordable Pricing", "We believe that learning Japanese should be affordable and accessible to everyone. We offer competitive pricing options for our Japanese language classes, ensuring that you receive excellent value for your investment. We understand that cost is an important factor for many learners, and our commitment to affordable education sets us apart from other institutions."],
        ["Quality Education", "While affordability is essential, we understand that quality education should never be compromised. At Koderbox School, we take pride in delivering top-notch Japanese language instruction at an affordable price. Our experienced instructors, comprehensive curriculum, and interactive learning environment ensure that you receive exceptional education that prepares you for real-world language use without breaking the bank."],
        ["Interactive Environment", "At Koderbox School, we foster a interactive learning environment where students can interact with one another. We believe that peer-to-peer interactions contribute to a richer language learning experience. Through group projects, pair work, and language exchange opportunities, you'll have the chance to practice your Japanese with fellow learners, exchange ideas, and learn from each other. "],
        ["Flexibility", "We recognize that students have diverse schedules and commitments. Koderbox School offers flexible class timings to accommodate different lifestyles. Whether you are a working professional, a student, or have other responsibilities, we have class options that can fit into your busy schedule. Our flexible timetables ensure that you can pursue Japanese language learning without disrupting your daily routines."],
        ["Supportive Community", "At Koderbox School, we are committed to providing ongoing support and guidance to our students. Our instructors are available to answer your questions, provide feedback on your progress, and offer assistance whenever you need it. Whether it's clarifying grammar points, practicing conversational skills, or seeking advice on study strategies, our dedicated instructors will be there to support you throughout your Japanese language learning journey."],
      ],
    },

    "german-class": {
      head: [
        "Are you eager to explore the richness of the German language and culture?",
        "Look no further! ",
        "Koderbox is here to provide you with an exceptional learning experience that combines convenience, affordability, and quality education. ",
      ],
      about: [
        "At Koderbox , We believe that learning a new language should be accessible to everyone, which is why we have designed affordable courses.  We offer competitive pricing options that fit within your budget, ensuring that language learning is within reach for all. ",
        "We are proud to be the only language institute in Delhi that offers german and other language classes at such affordable prices without compromising on the quality of education.  We believe that language learning should be engaging and interactive. Our courses incorporate various multimedia resources, interactive exercises, and real-life scenarios to enhance your language skills. ",
        "Through our interactive approach, you'll get enough opportunities to practice your speaking, listening, reading, and writing skills. You'll also receive valuable feedback from our instructors to help you improve and refine your language abilities. ",
        "At Koderbox , you'll become part of a supportive community of language learners. Our instructors are dedicated to your success and are always available to answer your questions and provide guidance. Additionally, you'll have the opportunity to connect with fellow students, share experiences, and practice your language skills together. ",
        "We believe that a supportive community enhances the language learning journey and creates a motivating environment for growth.  Start your journey today and discover the beauty of the german language and culture from the comfort of your own home.",
      ],
      service: [
        "At Koderbox, we take pride in our team of experienced and highly qualified German language instructors. We provide you with the most bright and talented teachers who are passionate about teaching and are dedicated to your success ",
        ["Expert Instructors" , "Our instructors are native speakers with a deep understanding of both the language and culture. They possess the necessary expertise to guide students effectively through the language-learning process, ensuring that you receive top-notch instruction tailored to your specific needs and learning style."],
        ["Affordable Pricing", "We believe that learning German should be affordable and accessible to everyone. We offer competitive pricing options for our German language classes, ensuring that you receive excellent value for your investment. We understand that cost is an important factor for many learners, and our commitment to affordable education sets us apart from other institutions."],
        ["Quality Education", "While affordability is essential, we understand that quality education should never be compromised. At Koderbox , we take pride in delivering top-notch German language instruction at an affordable price. Our experienced instructors, comprehensive curriculum, and interactive learning environment ensure that you receive exceptional education that prepares you for real-world language use without breaking the bank."],
        ["Interactive Environment", "At Koderbox, we foster a interactive learning environment where students can interact with one another. We believe that peer-to-peer interactions contribute to a richer language learning experience. Through group projects, pair work, and language exchange opportunities, you'll have the chance to practice your German with fellow learners, exchange ideas, and learn from each other. "],
        ["Flexibility", "We recognize that students have diverse schedules and commitments. Koderbox  offers flexible class timings to accommodate different lifestyles. Whether you are a working professional, a student, or have other responsibilities, we have class options that can fit into your busy schedule. Our flexible timetables ensure that you can pursue German language learning without disrupting your daily routines."],
        ["Supportive Community", "At Koderbox, we are committed to providing ongoing support and guidance to our students. Our instructors are available to answer your questions, provide feedback on your progress, and offer assistance whenever you need it. Whether it's clarifying grammar points, practicing conversational skills, or seeking advice on study strategies, our dedicated instructors will be there to support you throughout your german language learning journey."],
      ],
    },
    "coding": "Ready to begin your coding journey? Join KoderBox. KoderBox makes learning to code enjoyable and simple. Discover your full potential with our professional educators and hands-on initiatives. We've got you covered whether you want to design websites, apps, or learn about artificial intelligence.Join KoderBox today and make your coding aspirations a reality!"
  },
};
