import * as React from "react"
import '../../../gatsby-browser';
import {Helmet} from "react-helmet";
import { Link } from "gatsby";


class Footer extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    render(){
        return(
            <>

                <footer id="footer">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row footer-container">

                                <div className="col-lg-4 col-md-6 footer-info">
                                    <h3>KoderBox</h3>
                                    <p>
                                        Koderbox is strongly focused on creating customer oriented Software applications.
                                        The Development team minutely examines and analyses the existing IT processes of the
                                        Clients. It does the gap analysis to ensure complete compatibility between existing
                                        system and custom developed the application. The applications are designed, tested and
                                        deployed to complete satisfaction of the Client.
                                    </p>
                                </div>
                                <div className="col-lg-2 col-md-6 footer-links">
                                    <h4>Useful Links</h4>
                                    <ul>
                                        {/* <li><a href="/about/">About Us</a></li> */}
                                        {/* <li><a href="/howwedo/">How we do</a></li> */}
                                        {/* <li><a href="/contactus/">Contact Us</a></li> */}
                                        {/* <li><a href="/blogs/">Blogs</a></li> */}
                                        <li><Link  to="/about/">About Us</Link></li>
                                        <li> <Link  to="/howwedo/">How we do</Link></li>
                                        <li><Link  to="/contactus/">Contact Us</Link></li>
                                        <li><Link  to="/blogs/">Blogs</Link></li>
                                        
                                    </ul>
                                </div>

                                <div className="col-lg-2 col-md-6 footer-links">
                                    <h4>Useful Links</h4>
                                    <ul>
                                        <li><Link  to="/termsofservice/">Terms of service</Link></li>
                                        <li> <Link  to="/privacypolicy/">Privacy Policy</Link></li>
                                        <li><Link  to="/refundpolicy/">Cancellation & Refund Policy</Link></li>
                                        {/* <li><Link  to="/blogs/">Blogs</Link></li> */}
                                        
                                    </ul>

                                    
                                </div>
                               

                                <div className="col-lg-3 col-md-6 footer-contact">
                                    <h4>Contact Us</h4>
                                    <p>
                                        Rz-46 Vinoba Enclave Jharoda Kalan <br/>
                                        CRPF Camp Najafgarh<br/>
                                        New Delhi  110072 <br />
                                        <strong>Phone:</strong> +91-9718214022 <br/>
                                        <strong>WhatsApp:</strong> +91-9990484089 <br/>
                                        <strong>Call:</strong> +91-9641119187 <br/>
                                        <strong>Email:</strong>admin@koderbox.com <br/>
                                    </p>

                                    <div className="social-links">
                                        <a href="https://twitter.com/koderbox" className="twitter"><i className="fa fa-twitter"></i></a>
                                        <a href="https://www.facebook.com/koderbox" className="facebook"><i className="fa fa-facebook"></i></a>
                                        <a href="https://www.instagram.com/koderbox/" className="instagram"><i className="fa fa-instagram"></i></a>
                                        <a href="https://www.linkedin.com/company/koderbox/" className="linkedin"><i className="fa fa-linkedin"></i></a>
                                    </div>

                                </div>

                                {/* <div className="col-lg-3 col-md-6 footer-newsletter">

                                </div> */}

                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="copyright">
                            &copy; Copyright <strong>Koderbox</strong>. All Rights Reserved
                        </div>
                    </div>
                </footer>

            </>
        )
    }
}

export default Footer